<template>
    <div class="detail">
        <div class="handleBar">
            <div class="bar">
                <div class="name">数据详情</div>
                <div class="handle">
                    <el-button type="primary"> 下载数据</el-button>
                    <el-button type="primary" plain @click="back"> 返回列表</el-button>
                </div>
            </div>
        </div>
        <div class="module">
            <div class="m-left">
                <DetailModule mTitle="基础数据">
                    <template v-slot:content>
                        <div class="m-con">
                            <el-descriptions>
                                <el-descriptions-item label="英文名称" style="width:100%">Observation data in Chinese oceanic stations</el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions>
                                <el-descriptions-item label="数据时间">1999-2020</el-descriptions-item>
                                <el-descriptions-item label="共享级别">完全共享</el-descriptions-item>
                                <el-descriptions-item label="时效性">近实时</el-descriptions-item>
                                <el-descriptions-item label="更新频率">更新频率</el-descriptions-item>
                                <el-descriptions-item label="标识符">CSTR:13452.11.01.01.2021.19</el-descriptions-item>
                                <el-descriptions-item label="科学分类">海洋水文</el-descriptions-item>
                                <el-descriptions-item label="所有者">所有者</el-descriptions-item>
                                <el-descriptions-item label="共享方式">完全共享</el-descriptions-item>
                                <el-descriptions-item label="数据分类">实时数据</el-descriptions-item>
                                <el-descriptions-item label="关键字">共享方式</el-descriptions-item>
                                <el-descriptions-item label="共享方式">中国;14个海洋站;实时</el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions>
                                <el-descriptions-item label="引用方式">感谢国家科技资源共享服务平台—国家海洋科学数据中心(http://mds.nmdis.org.cn/)提供数据支撑；</el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </template>
                </DetailModule>
            </div>
            <div class="m-right">
                <DetailModule mTitle="数据集摘要">
                    <template v-slot:content>
                        <div class="m-con">
                            海洋站准实时数据（中国）格式是ASCII（字符）格式。中国海洋站数据涵盖了自1999年5月以来， 14个海洋站的各种准实时数据，包括海洋气象，波浪，温度和盐度。并经过解码，格式检查，代码转换，标准化，自动质量控制，可视化检查，校准等处理，形成标准化数据集。其中，质量控制包括范围检验，非法码检验，相关性检验，季节性检验，站代码检验和可视化图形检验等，该数据每月更新一次。
                        </div>
                    </template>
                </DetailModule>
            </div>
        </div>
        <div class="module2">
            <DetailModule mTitle="空间位置">
                <template v-slot:content>
                    <div class="m-con m-flex">
                        <div class="s-left">
                            <img src="../../assets/basedata/map.jpg" />
                        </div>
                        <div class="s-right">
                            <div class="h2">过滤条件</div>
                            <div class="f-box">
                                <el-form ref="form" :model="form" label-width="80px">
                                    <el-form-item label="站点名称">
                                        <el-select v-model="form.region" placeholder="请选择站点名称" style="width:100%;">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="年份">
                                        <el-select v-model="form.region" placeholder="请选择年份" style="width:100%;">
                                            <el-option label="2022" value="2022"></el-option>
                                            <el-option label="2021" value="2021"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                                <div class="m-handle">
                                    <el-button type="primary" size="small">查询</el-button>
                                    <el-button type="primary" plain size="small" style="margin-left:10px">重置</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </DetailModule>
        </div>
        <div class="module3">
            <div class="m-table">
                <DetailModule mTitle="数据清单">
                    <template v-slot:content>
                        <div class="m-top">
                            <el-button type="primary">
                                <div class="icon icon_bg1"></div>批量下载
                            </el-button>
                            <el-button type="warning">
                                <div class="icon icon_bg2"></div>订阅数据
                            </el-button>
                        </div>
                        <div class="m-bottom">
                            <Table :cloumns="cloumns" :tableData="tableData" @handleSelectionChange="handleSelectionChange">
                                <template #operate="{item}">
                                    <el-button @click.native.prevent="tohandle(item,'download')" type="text" size="small">
                                        下载
                                    </el-button>
                                    <el-button @click.native.prevent="tohandle(item,'add')" type="text" size="small">
                                        收藏
                                    </el-button>
                                </template>

                            </Table>
                            <!-- <el-table :data="tableData" :stripe="true" @selection-change="handleSelectionChange" style="width: 100%" size="medium">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="sizeName" label="站点名称" width="180">
                                </el-table-column>
                                <el-table-column prop="dataName" label="数据名称" width="140">
                                </el-table-column>
                                <el-table-column prop="date" label="数据接收时间" width="120">
                                </el-table-column>
                                <el-table-column prop="dataFormat" label="数据格式">
                                </el-table-column>
                                <el-table-column prop="year" label="年" width="60">
                                </el-table-column>
                                <el-table-column prop="mouth" label="月" width="60">
                                </el-table-column>
                                <el-table-column label="操作" width="120">
                                    <template slot-scope="scope">
                                        <el-button @click.native.prevent="tohandle(scope, tableData,'download')" type="text" size="small">
                                            下载
                                        </el-button>
                                        <el-button @click.native.prevent="tohandle(scope.$index, tableData,'add')" type="text" size="small">
                                            收藏
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table> -->
                        </div>
                        <Page :pagination="pagination" />
                    </template>
                </DetailModule>
            </div>
            <div class="m-list">
                <div class="list-top">
                    <DetailModule mTitle="本数据下载记录" :extra="true">
                        <template v-slot:extra>
                            <div class="more">
                                more <i class="el-icon-arrow-right
"></i>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="l-table">
                                <Table :cloumns="recordCloumns" :tableData="recordData" :height="308"></Table>
                            </div>
                        </template>
                    </DetailModule>
                </div>
                <div class="list-bottom">
                    <DetailModule mTitle="最近浏览数据集">
                        <template v-slot:content>
                            <div class="bot-con">
                                <div class="box-item" v-for="(item,index) in 10" :key="index">
                                    <span></span>
                                    <p>中国台站观测数据</p>
                                </div>
                            </div>
                        </template>
                    </DetailModule>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Page from '@/components/Page'
import Table from './Table.vue'
import { tableData, recordData } from "@/mockData"
import DetailModule from '@/components/DetailModule'
export default {
    name: "Detail",
    components: { DetailModule, Page, Table },
    data() {
        return {
            form: {
                name: '',
                date: ''
            },
            tableData: [],
            recordData: [],
            multipleSelection: [],
            pagination: {
                currentPage: 1,
                pageSize: 10,
                total: 24
            },
            recordCloumns: [
                {
                    index: 1,
                    label: "用户",
                    prop: "user",
                    width: "100",
                },
                {
                    index: 2,
                    label: "单位",
                    prop: "company",
                    width: "200",
                },
                {
                    index: 3,
                    label: "下载时间",
                    prop: "downLoadDate",
                    width: "200",
                },
            ],
            cloumns: [
                {
                    index: 1,
                    label: "",
                    prop: "",
                    width: "50",
                    type: "selection"
                },
                {
                    index: 2,
                    label: "站点名称",
                    prop: "sizeName",
                    width: "180",
                },
                {
                    index: 3,
                    label: "数据名称",
                    prop: "dataName",
                    width: "140",
                },
                {
                    index: 4,
                    label: "数据接收时间",
                    prop: "date",
                    width: "120",
                },
                {
                    index: 5,
                    label: "数据格式",
                    prop: "dataFormat",
                    width: "",
                },
                {
                    index: 6,
                    label: "年",
                    prop: "year",
                    width: "60",
                },
                {
                    index: 7,
                    label: "月",
                    prop: "mouth",
                    width: "60",
                },
                {
                    index: 8,
                    label: "操作",
                    prop: "operate",
                    width: "140",
                }
            ]
        }
    },
    mounted() {
        this.tableData = tableData
        this.recordData = recordData
    },
    methods: {
        back() {
            this.$router.back(0)
        },
        tohandle(data, type) {
            console.log(data);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style lang="less" scoped>
.detail {
    .handleBar {
        width: 100%;
        background-color: #fff;
        position: absolute;
        left: 0;

        .bar {
            height: 60px;
            width: 1400px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            margin: 0 auto;
            > .name {
                font-size: 18px;
                font-weight: bold;
                color: #444;
            }
        }
    }
    .module {
        padding-top: 90px;
        display: flex;
        justify-content: space-between;
        > .m-left {
            width: 870px;
        }
        > .m-right {
            width: 500px;
        }
        .m-con {
            padding: 20px;
            height: 256px;
            overflow-y: auto;
            font-size: 14px;
            line-height: 32px;
            color: #444;
            /deep/
                .el-descriptions-item__container
                .el-descriptions-item__label {
                color: #999;
            }
        }
    }
    .module2 {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .m-con {
            padding: 20px;
            > .s-left {
                width: 840px;
            }
            > .s-right {
                width: 480px;
                margin-left: 30px;
                .h2 {
                    width: 480px;
                    height: 52px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #1d78cd;
                    background-color: #f5f6fa;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 50px;
                }
                .m-handle {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .m-flex {
            display: flex;
            justify-content: space-between;
        }
    }
    .module3 {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 40px;
        > .m-table {
            width: 870px;
            .m-top {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 20px 30px;
                button {
                    width: 136px;
                    height: 40px;
                    .icon {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        margin-bottom: -3px;
                        margin-right: 6px;
                    }
                    .icon_bg1 {
                        background: url('../../assets/basedata/icon_check_on.png')
                            no-repeat;
                        background-size: 15px 15px;
                    }
                    .icon_bg2 {
                        background: url('../../assets/basedata/icon_dy_on.png')
                            no-repeat;
                        background-size: 15px 15px;
                    }
                    &:hover {
                        .icon_bg1 {
                            background: url('../../assets/basedata/icon_check_on.png')
                                no-repeat;
                            background-size: 15px 15px;
                        }
                        .icon_bg2 {
                            background: url('../../assets/basedata/icon_dy_on.png')
                                no-repeat;
                            background-size: 15px 15px;
                        }
                    }
                    &:focus {
                        .icon_bg1 {
                            background: url('../../assets/basedata/icon_check_on.png')
                                no-repeat;
                            background-size: 15px 15px;
                        }
                        .icon_bg2 {
                            background: url('../../assets/basedata/icon_dy_on.png')
                                no-repeat;
                            background-size: 15px 15px;
                        }
                    }
                }
            }
            .m-bottom {
                margin: 0 20px 20px 20px;
                /deep/ .el-table thead {
                    color: #4b5669;
                }
                /deep/ .el-table th.el-table__cell {
                    background-color: #f1f5f8;
                }
            }
        }
        > .m-list {
            width: 500px;
            .list-top {
                .more {
                    font-size: 14px;
                    color: #7682a1;
                    cursor: pointer;
                    &:hover {
                        color: #646e88;
                    }
                }
                .l-table {
                    padding: 20px;
                }
            }
            .list-bottom {
                margin-top: 30px;
                .bot-con {
                    padding: 20px;
                    height: 302px;
                    overflow-y: auto;
                    .box-item {
                        display: flex;
                        justify-content: flex-start;
                        height: 44px;
                        line-height: 44px;
                        align-items: center;
                        background-color: #f5f6fa;
                        padding: 0 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #1d78cd;
                        margin-bottom: 15px;
                        > span {
                            width: 4px;
                            height: 4px;
                            background-color: #1d78cd;
                            margin-right: 8px;
                        }
                        > p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>